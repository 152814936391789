import { styled } from '@liftfoils/styles'
import { SectionProductSizesProps } from '@liftfoils/models'
import { Container, Media, PortableText, Link } from '@liftfoils/components'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  $py: 'L',
  backgroundColor: '$gray100',
})

const ContainerHeading = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@md': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '& + div': {
    $mt: 'M',
  },
})

const HeadingWrappr = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@md': {
    maxWidth: '50%',
  },
})

const Title = styled('h2', {
  lift_font: 'heading02',
  color: '$gray300',
  '.highlighted': {
    color: '$gray500',
  },
  '& + p': {
    $mt: 'S',
  },
})

const ShortDescription = styled('p', {
  lift_font: 'body02',
  color: '$gray300',
  '@md': {
    maxWidth: '488px',
  },
})

const ButtonWrapper = styled('div', {
  mt: '$7',
  '@md': {
    mt: '$0',
  },
})

const Slider = styled('div', {
  display: 'grid',
  overflowX: 'scroll',
  overflowY: 'hidden',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  width: '100%',
  position: 'relative',
  $mt: 'S',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const ProductsWrap = styled('div', {
  px: '$containerMargin',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
})

const LineWrap = styled('div', {
  px: '$containerMargin',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  mt: '$8',
  '&::before': {
    content: '',
    width: '100%',
    height: '1px',
    background:
      'linear-gradient(90deg, rgba(54,183,178,1) 13%, rgba(54,183,178,0.15) 100%)',
    order: 2,
  },
})

const LineLabel = styled('div', {
  lift_font: 'body03',
  color: '$teal500',
  pr: '$7',
  variants: {
    isLastChild: {
      true: {
        order: 3,
        pr: '$0',
        pl: '$7',
      },
    },
  },
})

const Product = styled('div', {
  display: 'grid',
  gridGap: '$6',
  scrollSnapAlign: 'center',
  textAlign: 'center',
  minWidth: '250px',
  '@md': {
    width: '300px',
    minWidth: '300px',
  },
})

const ProdutTitle = styled('div', {
  lift_font: 'body05',
  color: '$gray500',
})
const ProductCtaWrap = styled('div', {
  display: 'flex',
  justifyContent: 'center',
})

export const SectionProductSizes: FC<SectionProductSizesProps> = ({
  title,
  buttonLabel,
  description,
  products,
  lineLabelLeft,
  lineLabelRight,
  buttonLink,
}) => {
  const { t } = useTranslation('product')
  return (
    <Wrap>
      <Container>
        <ContainerHeading>
          <HeadingWrappr>
            {title && (
              <Title>
                <PortableText value={title} />
              </Title>
            )}
            {description && (
              <ShortDescription>
                <PortableText value={description} />
              </ShortDescription>
            )}
          </HeadingWrappr>
          {buttonLabel && buttonLink && (
            <ButtonWrapper>
              <Link {...buttonLink} appearance={'solidGray500'} size={'medium'}>
                {buttonLabel}
              </Link>
            </ButtonWrapper>
          )}
        </ContainerHeading>
      </Container>
      <Slider>
        <ProductsWrap>
          {products?.map(({ link, title, image }, index) => {
            if (!link && validateLink(link)) return null
            return (
              <Product key={`${title}-${index}`}>
                <Link {...link} appearance={'block'}>
                  <Media {...image} sizes={'300px'} />
                  <ProdutTitle>{title}</ProdutTitle>
                </Link>
                <ProductCtaWrap>
                  <Link {...link} appearance={'solidGray500'} size={'small'}>
                    {t('buy')}
                  </Link>
                </ProductCtaWrap>
              </Product>
            )
          })}
        </ProductsWrap>
        {lineLabelLeft && lineLabelRight && (
          <LineWrap>
            <LineLabel>{lineLabelLeft}</LineLabel>
            <LineLabel isLastChild={true}>{lineLabelRight}</LineLabel>
          </LineWrap>
        )}
      </Slider>
    </Wrap>
  )
}
